/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MagentoData } from '../../../../services/MagentoApi';

import formStyles from '../../../../styles/input.module.scss';

const NoPaymentRequired = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const {
    emailAddress,
    paymentMethod,
    billingAddress,
    setLoading,
    setCheckoutSuccess,
  } = props;

  const payNow = async () => {
    setError(false);
    setLoading(true);
    setErrorMessage('');

    const email = emailAddress;
    const paymentResponse = await MagentoData.submitPayment(
      {},
      email,
      billingAddress,
      paymentMethod,
    );

    if (paymentResponse.status === 200) {
      setSuccess(true);
      setCheckoutSuccess();
    } else {
      const { message = '' } = paymentResponse;
      setError(true);
      setLoading(false);
      setErrorMessage(message);
    }
  };

  return (
    <div>
      <>
        {(error) && (
        <div className="Alert AlertError">
          Sorry, your order was unsuccessful.
          {errorMessage && (<span>{errorMessage}</span>)}
        </div>
        )}
        <div className={formStyles.formActions}>
          {(!success) && <button type="submit" onClick={() => payNow()}>Order now</button>}
          {(success) && (<p>Success! Please wait...</p>)}
          {(success && isDev) && (<p>Dev mode: Please manually clear your quote from browser data.</p>)}
        </div>
      </>
    </div>
  );
};

NoPaymentRequired.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
};

NoPaymentRequired.defaultProps = {
  paymentMethod: {
    code: '',
  },
  billingAddress: {},
  emailAddress: '',
  setLoading: () => {},
  setCheckoutSuccess: () => {},
};

export default NoPaymentRequired;
